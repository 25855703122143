import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo, ElephantLogo } from './icons'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__col'>
            <Link className='footer__logo' to='/'>
              <Logo/>
              <span>Back to Main Site</span>
            </Link>

            <div className='footer__item'>
              <div className='footer__address'>
                <p>Level 33 Aurora place</p>
                <p>88 Phillip st <br/>sydney nsw 2000</p>
              </div>

              <div className='footer__address'>
                <p>Level 27</p>
                <p>101 Collins street<br/>Melbourne vic 3000</p>
              </div>

              <h6>Australian Financial Services Licence (AFSL) No. 279186</h6>
            </div>
          </div>

          <div className='footer__col'>
            <div className='footer__item'>
              <div className='footer__address'>
                <div className='footer__link'>
                  <Link to='tel:+61290673133'>02 9067 3133</Link>
                  <Link to='mailto:info@vantageasset.com'>info@vantageasset.com</Link>
                  <Link to='/contact/'>enquire online</Link>
                </div>

                <Link className='logo' to='/'>
                  <ElephantLogo color='#000000' />
                </Link>
              </div>

              <h5>Australian Financial Services Licence (AFSL) No. 279186</h5>

              <div className='footer__disclaim'>
                <h6>© 2024 Vantage Asset Management Pty Limited</h6>
              </div>
            </div>

            <div className='footer__design'>
              <Link to='/'>
                <ElephantLogo color='#000000' />
              </Link>
              <Link to='https://atollon.com.au'>
                Design by Atollon
              </Link>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
